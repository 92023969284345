import React, { useState, useEffect } from "react";
import { Typography, Divider, Card, Avatar, Spin, Skeleton, Tooltip } from "antd";
import SupportRequestForm from "./components/util-components/SupportRequestForm";
import Flex from "./components/shared-components/Flex";

import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const SupportRequest = (props) => {
  const [authData, setAuthData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(undefined);
  useEffect(() => {
    let _authData = getAuthDataFromStorage();
    if (_authData) {
      let _d = { email: _authData.email, name: _authData.name, picture: _authData.picture };
      setAuthData(_d);
    }
    setTimeout(() => setIsLoading(true), 600);
  }, []);

  const getAuthDataFromStorage = () => {
    try {
      return JSON.parse(localStorage.getItem("SalesPark-AuthBasic"));
    } catch (e) {
      return undefined;
    }
  };
  const apiVersion = () => {
    if (props.apiVersion !== undefined) {
      return (
        <span className="ant-typography ant-typography-secondary">
          <small>(API: ${props.apiVersion})</small>
        </span>
      );
    }
    return <></>;
  };

  return (
    <>
      <div className="my-5 container" style={{ display: isLoading === undefined ? "block" : "none" }}>
        <Spin size="large" tip="Está quase..." spinning={isLoading === undefined}>
          <Skeleton active />
          <Skeleton active />
          <Card>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Card>
        </Spin>
      </div>
      <div
        className="my-5 container"
        style={{ paddingBottom: 50, display: isLoading === undefined ? "none" : "block" }}
      >
        <Flex>
          <img src="/img/logo.png" alt="SalesPark" />
          <Title type="secondary" level={4} style={{ paddingLeft: 50, paddingTop: 12, alignSelf: "center" }}>
            <Link to={{ pathname: "https://salespark.io/redirect/?u=central-ajuda" }} target="_blank">
              Central de Ajuda
            </Link>
          </Title>
        </Flex>
        {!!authData && (
          <>
            <Avatar src={authData?.picture} />
            &nbsp;&nbsp;&nbsp;
            <span>
              <strong>Olá {authData?.name}!</strong>
            </span>
            <br /> <br />
          </>
        )}
        <div style={{ paddingTop: 25, paddingBottom: 30 }}>
          <Title level={1}>Pedido de Suporte</Title>
          <Text type="secondary">
            Usa este espaço colocares as tuas questão ao suporte. Iremos ajudar-te o mais rápido possível.
          </Text>
          <Divider dashed />
        </div>

        <Card>
          <SupportRequestForm auth={authData} />
        </Card>

        <footer className="footer">
          <span>
            Copyright &copy; {`${new Date().getFullYear()}`} <span className="font-weight-semibold">SalesPark</span>{" "}
            - Feito com{" "}
            <Tooltip placement="right" title="Feito com amor e muito café">
              ❤️ e ☕
            </Tooltip>{" "}
            - versão 2.4.703 {apiVersion()}
          </span>
          <div>
            <Link to={{ pathname: "https://salespark.io/redirect/?u=general-terms-of-use" }} target="_blank">
              <Text type="secondary">Termos e Políticas</Text>
            </Link>

            <span className="mx-2 text-muted"> | </span>
            <Link to={{ pathname: "https://salespark.io/redirect/?u=central-ajuda" }} target="_blank">
              <Text type="secondary">Central de Ajuda</Text>
            </Link>
          </div>
        </footer>
      </div>
    </>
  );
};

export default SupportRequest;
