import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "SalesPark";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = ""; // /app
export const AUTH_PREFIX_PATH = "/auth";
export const MIN_PRICE = 2.5;
export const MAX_PRICE = 49999.99;
export const MIN_RECURRENCE_MONTHS = 1;
export const MAX_RECURRENCE_MONTHS = 360;
export const MIN_RECURRENCE_PRICE = 2.5;
export const MAX_RECURRENCE_PRICE = 49999.99;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "pt",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};
