import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Avatar, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { SettingOutlined, QuestionCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import IntlMessage from "../util-components/IntlMessage";
import { getProfileImage } from "../util-components/Auth";
import Package from "../../../package.json";
import VerifiedAccount from "../util-components/VerifiedAccount";

const menuItem = [
  {
    title: <IntlMessage id={"navigator.profile.account_settings"} />,
    icon: SettingOutlined,
    path: "/account",
  },

  // {
  //   title: <IntlMessage id={"navigator.profile.account_settings"} />,
  //   icon: SettingOutlined,
  //   path: "/",
  // },
  // {
  //   title: <IntlMessage id={"navigator.profile.billing"} />,
  //   icon: ShopOutlined,
  //   path: "/",
  // },
  {
    title: <IntlMessage id={"navigator.profile.help_center"} />,
    icon: QuestionCircleOutlined,
    external: true,
    path: "https://salespark.io/redirect/?u=central-ajuda",
  },
];

const initChat = (auth, uniqueUserId) => {
  try {
    // var Tawk_API = window.Tawk_API || undefined;
    // if (Tawk_API === undefined) return;
    // Tawk_API.setAttributes(
    //   {
    //     user_type: "Producer",
    //     producer_id: auth?.user?.id || 999,
    //     name: auth?.user?.name,
    //     email: auth?.user?.email,
    //     hash: uniqueUserId,
    //   },
    //   function (error) {}
    // );
    if (!auth?.email) return;

    var producerData = {
      //Custom Profile fields
      contact_first_name: auth?.user?.name,
      contact_last_name: "",

      customer_id: auth?.user?.id,
      producer_id: auth?.user?.id,
      account_status: auth?.account_status,
      company_legal_type: auth?.company_legal_type,
      email_status: auth?.email_status?.status,
      vat_exemption_53: auth?.vat_exemption_53 ? "Sim" : "Não",
      verifiedAccount: auth?.verifiedAccount ? "Sim" : "Não",
    };

    window.groove.widget.identifyContact("contact_email", auth?.email);
    window.groove.widget.setContact(producerData);
  } catch (error) {
    //
  }
};

export const NavProfile = ({ auth, logout }) => {
  const [profileImage] = useState(getProfileImage(auth));
  const uniqueUserId = `appv2@${auth?.email}`;
  if (auth !== undefined) {
    if (auth !== undefined && window.groove.widget) {
      var producerData = {
        contact_first_name: auth?.user?.name,
        contact_last_name: "",

        //Custom Profile fields

        contact_email: auth?.email,
        customer_id: auth?.user?.id,
        producer_id: auth?.user?.id,
        account_status: auth?.account_status,
        company_legal_type: auth?.company_legal_type,
        email_status: auth?.email_status?.status,
        vat_exemption_53: auth?.vat_exemption_53 === true ? "Sim" : "Não",
        verifiedAccount: auth?.verifiedAccount === true ? "Sim" : "Não",
      };

      window.groove.widget.identifyContact("contact_email", auth?.email);
      window.groove.widget.setContact(producerData);
    }
    // if (auth !== undefined && window.Tawk_API) {
    //   window.Tawk_API.onLoad = function () {
    //     window.Tawk_API.setAttributes(
    //       {
    //         name: auth?.user?.name,
    //         email: auth?.user?.email,
    //         hash: uniqueUserId,
    //       },
    //       function (error) {}
    //     );
    //   };
    //   window.Tawk_API.visitor = {
    //     name: auth?.user?.name,
    //     email: auth?.user?.email,
    //     hash: uniqueUserId,
    //   };
    // }
  }

  useEffect(() => {
    initChat(auth, uniqueUserId);
  }, []);

  if (auth === undefined) return <></>;
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar src={profileImage} />

          <div className="pl-3">
            <Link to={{ pathname: "/account/personal-data" }}>
              <div
                className="mb-0"
                style={{
                  fontWeight: 600,
                  width: "144px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <VerifiedAccount verified={auth?.verifiedAccount === true} />
                {auth?.user?.name}
              </div>
            </Link>
            <span className="text-muted">{"Administrador"}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return el.external === true ? (
              <Menu.Item key={i}>
                <Link to={{ pathname: el.path }} target="_blank">
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            ) : (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={(e) => logout()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">
                <IntlMessage id={"navigator.profile.sign_out"} />
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Tooltip title="Opções de Conta">
            <Avatar src={profileImage} />
          </Tooltip>
          {/* 
          Code causes multiple re-renders when Socket connection change (server restarts)
          {auth?.socketConnected && (
            <Tooltip title="Ligado ao serviço de notificações">
              <Badge dot color="green" style={{ top: -15, right: 10 }}></Badge>
            </Tooltip>
          )} 
          */}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

// export default connect(null, { signOut })(NavProfile);
export default React.memo(NavProfile);
