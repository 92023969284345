import { useEffect, memo } from "react";
import socketIOClient from "socket.io-client";
import { useAuth } from "context/Auth";
import { notification, message } from "antd";
import useSound from "use-sound";
import kaChingSfx from "../../../assets/sounds/ka-ching.mp3";
const ENDPOINT = process.env.REACT_APP_WS_URL;

const env = process.env.REACT_APP_ENVIRONMENT.toLowerCase() || "development";
// Setup Socket Endpoint and transports
let socket;
const SocketIO = () => {
  const { auth, setAuth } = useAuth();
  const [play] = useSound(kaChingSfx, { volume: 0.2 });

  useEffect(() => {
    processSocket();

    // return () => {
    //   socket.off("connect");
    //   socket.off("disconnect");
    //   socket.off("precheckout");
    //   socket.off("sale");
    //   socket.off("checkout-events");
    // };
  }, []);

  const emitEvent = (event, data) => {
    try {
      if (socket) {
        socket.emit(event, data);
      }

      // Error Handling
    } catch (e) {
      console.log("Error emitting event", e);
    }
  };

  const processSocket = () => {
    try {
      socket = socketIOClient(ENDPOINT, { transports: ["websocket", "polling"] });

      socket.on("connect_failed", function () {
        if (env === "development") {
          console.log("connect_failed");
        }
      });

      socket.on("error", function () {
        if (env === "development") {
          console.log("error");
        }
      });

      socket.on("connect_error", (err) => {
        if (env === "development") {
          console.log("connect_error", err);
        }
      });

      socket.on("connect", function () {
        if (env === "development") {
          console.log("connect");
        }
        emitEvent("user-connected", { token: auth.data.token, socket_id: socket.id });
      });

      socket.on("disconnect", function () {
        if (env === "development") {
          console.log("disconnect");
        }
        socket.off("connect");
        socket.off("disconnect");
        socket.off("precheckout");
        socket.off("sale");
        socket.off("checkout-events");
      });

      socket.on("connection-accepted", (data) => {
        console.log("Connection accepted", data);
        // let s = { ...store };
        //   s.socketid = data.id;
        //setStore(s);
        //  setAuth({ ...auth, socketConnected: true });
      });

      socket.on("precheckout", (data) => {
        notification.info({
          placement: "bottomLeft",
          duration: 5,
          message: "Pré-Checkout",
          description: `Novo pré-checkout de "${data.product_name}" efetuado por ${data.buyer}`,
        });
      });

      socket.on("sale", (data) => {
        play();
        notification.info({
          placement: "bottomLeft",
          duration: 5,
          message: "Venda Efetuada",
          description: `Nova venda de "${data.product_name}" efetuado por ${data.buyer}`,
        });
      });

      socket.on("checkout-events", (data) => {
        // setStore({ messagebar: data });
      });

      // Error Handling
    } catch (error) {
      console.error("SocketIO Error", error);
    }
  };

  return <></>;
};

export default SocketIO; //memo(SocketIO);
