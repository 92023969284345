import React, { useState, useEffect } from "react";
import { Button, Modal, Grid, Spin } from "antd";
import { PlayCircleTwoTone, DoubleRightOutlined } from "@ant-design/icons";
import YouTube from "react-youtube";
import utils from "utils";

const { useBreakpoint } = Grid;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/**
 *
 * Modal Video Component
 *
 */
const ModalVideo = ({ isMobile, videoModal, setVideoModal, isLoading, setIsLoading }) => {
  const _videoId = videoModal?.videoId;
  let isYT = false;
  let ytVideoId = "";
  if (_videoId === null || _videoId === undefined || _videoId === "") {
    return <></>;
  }

  let videoUrl = "";

  if (_videoId?.includes("youtube.com/")) {
    videoUrl = videoModal.videoId;
    ytVideoId = _videoId.split("https://www.youtube.com/embed/")[1];
    isYT = true;
  } else {
    setIsLoading(false);
    videoUrl = `https://www.loom.com/embed/${_videoId}?hideEmbedTopBar=true`;
  }

  const KBLink = () => {
    if (videoModal.kbUrl) {
      return (
        <div style={{ marginBottom: 15, display: "flex", justifyContent: "flex-end", fontSize: 17 }}>
          <a href={videoModal.kbUrl} target="_blank" rel="noreferrer">
            <Button type="primary">
              Ver artigo de ajuda <DoubleRightOutlined />
            </Button>
          </a>
        </div>
      );
    }
    return <></>;
  };

  return (
    <Modal
      destroyOnClose={true}
      width={isMobile ? "90%" : "1000px"}
      style={{
        top: 20,
      }}
      className="modal-video-help"
      visible={videoModal.visible}
      title={videoModal.title ? videoModal.title : "Bem Vindo à SalesPark!"}
      onOk={() => setVideoModal({ visible: false, videoId: null, title: null })}
      onCancel={() => setVideoModal({ visible: false, videoId: null, title: null })}
      footer={[
        <Button
          style={{ paddingLeft: 20, paddingRight: 20 }}
          type="default"
          onClick={() => {
            setVideoModal({ visible: false, videoId: null, title: null });
          }}
        >
          Fechar
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <div style={{ paddingLeft: isMobile ? 0 : 10, paddingRight: isMobile ? 0 : 10 }}>
          <KBLink />

          {isYT === false && videoModal?.videoId !== undefined && videoModal?.videoId !== null && (
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
              <iframe
                title={videoModal?.title}
                id="video-iframe-help"
                src={videoUrl}
                frameborder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowfullscreen="true"
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              ></iframe>
            </div>
          )}
          {isYT === true && videoModal?.videoId !== undefined && videoModal?.videoId !== null && (
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
              <YouTube
                className="react-player"
                videoId={ytVideoId} // defaults -> ''
                id="video-iframe-help"
                onReady={() => setIsLoading(false)}
              />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

const HelpVideo = ({ pageVideo }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [videoModal, setVideoModal] = useState({
    visible: false,
    videoId: null,
    title: null,
    kbUrl: null,
  });

  const screens = utils.getBreakPoint(useBreakpoint());

  const { width } = getWindowDimensions();
  const isMobile = !screens.includes("lg") || width < 1280;

  return (
    <>
      <Button
        size="small"
        type="default"
        className="btn-help-video"
        onClick={() => {
          if (pageVideo) {
            setVideoModal({
              visible: true,
              videoId: pageVideo?.videoId,
              title: pageVideo?.title || "Ajuda",
              kbUrl: pageVideo?.kbUrl,
            });
          }
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="pulsingButtonViewHelpVideo">
            <PlayCircleTwoTone twoToneColor={"#ff6b72"} style={{ fontSize: "16px", verticalAlign: 0 }} />
          </span>
          <span style={{ marginLeft: 10 }}>Ver video de ajuda</span>
        </div>
      </Button>
      {videoModal.visible === true && (
        <ModalVideo
          isMobile={isMobile}
          videoModal={videoModal}
          setVideoModal={setVideoModal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default HelpVideo;
