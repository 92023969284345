const backgroundType = "gradientLinear";
const backgroundRotation = 45;
const fontSize = 40;
const backgroundColor =
  "9ff4ff,bbdbf7,cdbeeb,d7e9c4,e2b3ef,ace3fd,c3e5c4,8ffff3,bfc5e9,ecefc1,f6b7ce,f7c3c2,fbcabb,ffdcb1,fef3c2,ffe8b2";

export default function dicebarString(name, type = "initials") {
  if (type === "person-avatar") {
    type = "big-ears"; //"notionists-neutral";
  }
  if (type === "product-avatar") {
    type = "shapes";
  }

  if (!name) return "https://api.dicebear.com/8.x/initials/svg?seed=??";

  name = name.replace(/\s/g, "+").replace(/\s\s+/g, "-").replace(/#/g, "");
  return `https://api.dicebear.com/8.x/${type}/svg?seed=${name}&backgroundType=${backgroundType}&backgroundRotation=${backgroundRotation}&fontSize=${fontSize}&backgroundColor=${backgroundColor}`;
}
