import React, { useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Drawer, Menu, notification, Tooltip } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { DIR_RTL } from "constants/ThemeConstant";

import { producer_settings_get } from "api/producers";
import {
  toggleCollapsedNav,
  onNavTypeChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onSwitchTheme,
  onLocaleChange,
  onDirectionChange,
} from "redux/actions/Theme";
import store from "redux/store";

import { useAuth } from "context/Auth";
const NavPanel = (props) => {
  const { auth } = useAuth();
  const [state, setState] = useState({ visible: false });

  const showDrawer = () => {
    setState({
      visible: true,
    });
  };

  const onClose = () => {
    setState({
      visible: false,
    });
  };

  const reloadProducerSettings = () => {
    producer_settings_get(auth.data)
      .then((res) => {
        if (res.data.settings !== undefined) {
          store.dispatch(toggleCollapsedNav(res.data.settings.navCollapsed));
          store.dispatch(onNavTypeChange(res.data.settings.navType));
          store.dispatch(onTopNavColorChange(res.data.settings.topNavColor));
          store.dispatch(onHeaderNavColorChange(res.data.settings.headerNavColor));
          store.dispatch(onSwitchTheme(res.data.settings.currentTheme));
          store.dispatch(onDirectionChange(res.data.settings.direction));
          store.dispatch(onLocaleChange(res.data.settings.locale));
        }
      })
      .catch((err) => {
        notification.error({
          message: "Oops!",
          description: "Erro ao carregar as configurações... Tenta novamente mais tarde.",
        });
      });
  };
  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item key="panel" onClick={showDrawer}>
          <Tooltip title="Configuração do Tema" placement="bottomLeft">
            <span>
              <SettingOutlined className="nav-icon mr-0" />
            </span>
          </Tooltip>
        </Menu.Item>
      </Menu>
      <Drawer
        title="Configuração do Tema"
        placement={props.direction === DIR_RTL ? "left" : "right"}
        width={350}
        onClose={(e) => {
          reloadProducerSettings();
          onClose();
        }}
        visible={state.visible}
      >
        <ThemeConfigurator
          onClose={() => {
            reloadProducerSettings();
            onClose();
          }}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
