import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Upload, Result, notification, Spin, message } from "antd";
import { iam_user, iam_affiliated, iam_producer, iam_partner } from "./iam";
import { InboxOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { support_request_post } from "../../../api/support";
import { Link } from "react-router-dom";
import uniqid from "uniqid";

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const SupportRequestForm = (props) => {
  const [form] = Form.useForm();

  const { email, producer_id, auth } = props;
  const [state, setState] = useState({ iam: undefined });
  const [supportRequestUniqueId] = useState(uniqid());
  const [fileLoading, setFileLoading] = useState(false);
  const [uploadFileList, setUploadFileList] = useState({ fileList: [] });
  const [authData, setAuthData] = useState(undefined);
  const [postStatus, setPostStatus] = useState(undefined);

  useEffect(() => {
    if (auth) {
      let _d = { email: auth.email, name: auth.name, uniqueId: supportRequestUniqueId };
      setAuthData(auth);
      setState(_d);
      form.setFieldsValue(_d);

      if (window.jivo_api)
        window.jivo_api.setContactInfo({
          name: auth.name,
          email: auth.email,
          description: "Producer - Support",
        });
    }
  }, [window.jivo_api, auth]);

  const styleFormItem = {
    style: {
      width: "100%",
      maxWidth: "450px",
    },
  };

  const uploadAction = (data) => {
    return `${process.env.REACT_APP_API_URL}/upload/public/file/support/documents/?content_type=${data.type}&uniqueId=${supportRequestUniqueId}`;
  };

  const uploadProps = {
    name: "file",
    accept: ".png, .jpeg, .jpg, .pdf",
    multiple: true,
    showUploadList: true,
    maxCount: 10,
    action: uploadAction,
    beforeUpload: beforeUpload,
    onChange(info) {
      try {
        const { status } = info.file;

        if (status === "uploading") {
          setFileLoading(true);
        }
        if (status === "done") {
          setUploadFileList({ fileList: info.fileList });
          setFileLoading(false);

          message.success("Ficheiro carregado com sucesso!");
        } else if (status === "error") {
          notification.error({
            duration: 3,
            message: "Erro",
            description: "Erro ao carregar o ficheiro...",
          });
          setFileLoading(false);
        }
      } catch (error) {
        notification.error({
          duration: 3,
          message: "Erro",
          description: "Erro ao carregar o ficheiro...",
        });
        setFileLoading(false);
      }
    },
  };

  function beforeUpload(file) {
    const isValidFile =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp" ||
      file.type === "application/pdf";
    if (!isValidFile) {
      notification.error({
        duration: 2,
        message: "Erro",
        description: "Tens de selecionar um ficheiro válido!",
      });
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      notification.error({
        duration: 2,
        message: "Erro",
        description: "o ficheiro não pode ter mais do que 5MB!",
      });
    }

    return isValidFile && isLt5M;
  }

  const populateReasons = (iam) => {
    if (!iam === undefined) return <></>;
    let jsonData = [];

    switch (iam) {
      case "iam-user":
        jsonData = iam_user;
        break;
      case "iam-affiliated":
        jsonData = iam_affiliated;
        break;
      case "iam-producer":
        jsonData = iam_producer;
        break;
      case "iam-partner":
        jsonData = iam_partner;
        break;
      default:
        jsonData = ["Outro assunto"];
        break;
    }

    return jsonData.map((el, i) => (
      <Option key={i} value={el}>
        {el}
      </Option>
    ));
  };

  const handleSubjectChange = (e) => {
    let _d = { iam: e, reason: undefined };
    setState(_d);
    form.setFieldsValue(_d);
  };

  const onFinish = async (values) => {
    values.fileList = parseFileList(values.fileList);
    let res = await AddNewSupportRequest(values);
    if (res.status) {
      setPostStatus(true);
    }
  };

  const parseFileList = (files) => {
    if (!files) return [];
    if (!Array.isArray(files.fileList)) return [];
    if (files.fileList.length === 0) return [];

    return files.fileList.map((el) => ({ uid: el.uid, name: el.name, status: "done", url: el.response.url }));
  };

  const AddNewSupportRequest = (data) => {
    return new Promise((resolve) => {
      support_request_post(data)
        .then((res) => {
          resolve({ status: true, data: res });
        })
        .catch((err) => {
          resolve({ status: false, data: err });
          notification.error({
            duration: 6,
            message: "Oops!",
            description: "Ocorreu um erro ao submeter o teu pedido. Tenta novamente mais tarde.",
          });
        });
    });
  };

  const onFinishFailed = (values) => {};

  return (
    <>
      <div style={{ display: postStatus ? "block" : "none" }}>
        <Result
          status="success"
          title="O teu pedido foi enviado com sucesso!"
          subTitle="Podes também contatar o suporte atavés do e-mail suporte@salespark.io"
          extra={[
            <Link to={{ pathname: "https://salespark.io/redirect/?u=central-ajuda" }} target="_blank">
              <Button type="primary" key="console">
                Ir para a Central de Ajuda
              </Button>
            </Link>,
          ]}
        />
      </div>
      <div style={{ display: postStatus === undefined ? "block" : "none" }}>
        <Form
          layout="vertical"
          initialValues={state}
          form={form}
          style={{ textAlign: "left" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="uniqueId" style={{ display: "none" }}>
            <Input type={"hidden"} />
          </Form.Item>

          <Form.Item
            name="iam"
            label="Assunto"
            {...styleFormItem}
            rules={[
              {
                required: true,
                message: "Campo Obrigatório",
              },
            ]}
            hasFeedback
          >
            <Select onChange={(e) => handleSubjectChange(e)}>
              <Option value="iam-user">Comprei um produto pela SalesPark</Option>
              <Option value="iam-partner">Sou um Parceiro</Option>
              <Option value="iam-producer">Sou um Produtor</Option>
              <Option value="iam-affiliated">Sou um Afiliado</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="reason"
            label="Motivo do contacto"
            {...styleFormItem}
            rules={[
              {
                required: true,
                message: "Campo Obrigatório",
              },
            ]}
            hasFeedback
          >
            <Select disabled={state?.iam === undefined}>{populateReasons(state?.iam)}</Select>
          </Form.Item>

          <Form.Item
            name="name"
            label="Nome"
            {...styleFormItem}
            rules={[
              {
                required: true,
                message: "Campo Obrigatório",
              },
            ]}
            hasFeedback
          >
            <Input disabled={authData?.name !== undefined} />
          </Form.Item>

          <Form.Item
            name="email"
            label="Endereço de e-mail"
            {...styleFormItem}
            rules={[
              {
                required: true,
                message: "Campo Obrigatório",
              },
            ]}
            hasFeedback
          >
            <Input disabled={authData?.email !== undefined} type="email" />
          </Form.Item>

          <Form.Item
            label="Descrição"
            name="description"
            rules={[
              {
                required: true,
                message: "Campo Obrigatório",
              },
              {
                min: 40,
                message: "A descrição tem de ter mais de 40 caracteres.",
              },
              {
                max: 800,
                message: "A descrição não pode conter mais de 800 caracteres.",
              },
            ]}
            hasFeedback
          >
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item label="Anexos (opcional)" name="fileList">
            <Dragger {...uploadProps} disabled={uploadFileList.length > 10}>
              {fileLoading ? (
                <Spin tip="A carregar ficheiro..."></Spin>
              ) : (
                <div>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Clica aqui ou arrasta para aqui a imagem</p>
                  <p className="ant-upload-hint">
                    Suporte apenas a ficheiros de imagem comuns e PDF's (.png, .jpeg, .jpg, .pdf)
                  </p>
                </div>
              )}
            </Dragger>
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button size="large" type="primary" htmlType="submit">
                Submeter Pedido de Suporte <ArrowRightOutlined />
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default SupportRequestForm;
