import React from "react";
import { Tooltip } from "antd";
import { NotificationTwoTone } from "@ant-design/icons";
const Noticeable = () => {
  window.noticeable.render("widget", "Ml0q5xxcFwPJWNoYPk4H");
  return (
    <div
      id="noticeable-widget"
      style={{ paddingRight: 6, cursor: "pointer", width: 38 }}
      onClick={() => window.noticeable.do("widget:open", "Ml0q5xxcFwPJWNoYPk4H")}
    >
      <Tooltip title="Últimas Atualizações & Novidades">
        <NotificationTwoTone twoToneColor="#eb2f96" />{" "}
      </Tooltip>
    </div>
  );
};

export default React.memo(Noticeable);
