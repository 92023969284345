import axios from "axios";
const env = process.env.REACT_APP_ENVIRONMENT.toLowerCase() || "development";

const api_request = (auth_data) => {
  const adminToken = localStorage.getItem(`${env}-salespark-admin-token`);

  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: env === "development" ? 300000 : 30000,
    headers: {
      "Content-Type": "application/json",
      "x-email": auth_data.email,
      "x-guserid": auth_data.guserid,
      "x-token": auth_data.token,
      "x-admin-token": adminToken,
    },
  });
};
const checkAuth = (resp) => {
  //TODO: #6:
  //Impement on components on catch: If status is 401 execute Google Signout
  try {
    //  if (resp.response.status === 403) window.location = "/admin-2fa";
    if (resp.response.status === 401) {
      // window.location = "/dashboard";
    }
  } catch (error) {}
};

export const producer_status_update = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/admin/producers/status", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_score_update = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/admin/producers/score", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_plan_update = async (auth_data, new_plan) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/admin/producers/plan", new_plan)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_account_status_update = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/admin/producers/account-status", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const metrics_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/metrics")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const metrics_memcache_latest_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/metrics/memcache")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const producers_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/producers", { params: filter })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producers_pagination_get = async (auth_data, limit, page, filter, select, orderBy, orderDirection) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/producers/v2", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producers_minimal_get = async (auth_data, filter, select) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/producers/minimal", { params: { filter, select } })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_products_offers_get = async (auth_data, producer_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/products-offers/producer/${producer_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_update_balance_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/admin/producers/update-balance-ledger", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_documents_update = async (auth_data, _id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/admin/producers/company-document/${_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const sales_recent_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/sales/recent")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const producer_sales_get = async (auth_data, producer_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/sales/producer/${producer_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const products_to_approve_get = async (auth_data, status) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/products/approval", {
        params: {
          status,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const products_bundle_content_get = async (auth_data, products) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/products/bundle-content/${products}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const product_to_approve_get = async (auth_data, product_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/products/approval/${product_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const product_to_approve_notes_get = async (auth_data, product_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/products/approval/${product_id}/notes`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const producer_get = async (auth_data, producer_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/producer/${producer_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const products_status_put = async (
  auth_data,
  _id,
  product_id,
  producer_id,
  status_code,
  status_message,
  status_details,
  notes,
  notify_producer
) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/admin/products/approval", {
        _id,
        product_id,
        producer_id,
        status_code,
        status_message,
        status_details,
        notes,
        notify_producer,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const products_approval_confirm_put = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/admin/products/approval/confirm", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const course_get = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/courses/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const course_as_student_post = async (auth_data, course_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`admin/get-course-as-student/${course_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const payments_list = async (auth_data, limit, page, filter, select, orderBy, orderDirection) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/payments", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const payment_set_as_paid_post = async (auth_data, _id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/admin/set-payment-as-paid/${_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const app_notices_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/app-notices`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const app_active_notice_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/app-active-notices`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const app_notice_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/admin/app-notices`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const app_notice_put = async (auth_data, _id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/admin/app-notices/${_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const app_notice_delete = async (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/admin/app-notices/${_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const admin_online_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/online")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const admin_online_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/admin/online", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

/**
 *
 *
 * REFUNDS
 *
 *
 *
 */

export const refunds_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/refunds`, {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const refunds_put = async (auth_data, _id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/admin/refunds/${_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const refund_get = async (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/refunds/${_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

/**
 *
 *
 * PAYOUTS
 *
 *
 *
 */

export const payouts_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/payouts`, {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const payouts_put = async (auth_data, _id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/admin/payouts/${_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const payout_get = async (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/payouts/${_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

/**
 *
 *
 * BANK ACCOUNTS
 *
 *
 *
 */

export const bank_accounts_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/bank-accounts`, {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const bank_account_put = async (auth_data, _id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/admin/bank-accounts/${_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const bank_account_get = async (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/bank-accounts/${_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const plan_get = async (auth_data, plan_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/plans/${plan_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const plans_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/plans")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const products_list = async (auth_data, producer_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/products/producer/${producer_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const product_with_co_op_get = async (auth_data, product_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/products/co-op/${product_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const trigger_co_op_sale_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/admin/trigger-co-op-sale`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const courses_list = async (auth_data, producer_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/courses/producer/${producer_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const classes_list = async (auth_data, producer_id, course_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/classes/producer/${producer_id}/course/${course_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const bankaccounts_list = async (auth_data, producer_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/bank-accounts/producer/${producer_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const payouts_list = async (auth_data, producer_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/payouts/producer/${producer_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};

export const global_metrics_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/global-metrics")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const global_metrics_month_get = async (auth_data, year, month) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/global-metrics/${year}/${month}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const transactions_invoicing_get = async (
  auth_data,
  limit,
  page,
  filter,
  select,
  orderBy,
  orderDirection
) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/transactions-invoicing", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const sales_invoicing_get = async (auth_data, payment_unique_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/sales-invoicing/${payment_unique_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const sales_transactions_get = async (auth_data, payment_unique_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/sales-transactions/${payment_unique_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const interactions_get = async (auth_data, entity_type, entity_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/interactions/${entity_type}/${entity_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const sale_transaction_get = async (auth_data, payment_unique_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/sale-transaction/${payment_unique_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const sales_invoicing_order_get = async (auth_data, payment_unique_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/transactions-invoicing/order/${payment_unique_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const sales_invoicing_issue_doc_post = async (auth_data, transaction_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/admin/transactions-invoicing/issue-document/${transaction_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const transaction_invoicing_get = async (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/transactions-invoicing/${_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const users_students_get = async (auth_data, limit, page, filter, select, orderBy, orderDirection) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/users-students", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const buyers_get = async (auth_data, limit, page, filter, select, orderBy, orderDirection) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/admin/buyers", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const buyer_get = async (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/buyers/${_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const users_students_set_password_put = async (auth_data, user__id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/admin/users-students/${user__id}/set-password`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const users_students_update_email_put = async (auth_data, user__id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/admin/users-students/${user__id}/update-email`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const user_students_get = async (auth_data, user__id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/users-students/${user__id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const user_students_import_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/admin/users-students/import", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const rebuild_sale_post = async (auth_data, payment_unique_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/admin/rebuild-sale/${payment_unique_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const installments_cancel_put = async (auth_data, purchase_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/admin/installments/cancel/${purchase_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const installments_info_get = async (auth_data, purchase_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/admin/installments/info/${purchase_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
//
