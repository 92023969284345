import axios from "axios";

const api_request = (auth_data) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "development" ? 300000 : 15000,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const checkAuth = (resp) => {
  //TODO:
  //Impement on components on catch: If status is 401 execute Google Signout
};

export const support_request_post = async (body) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/actions/support-request", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject(err);
      });
  });
};
