import {
  EuroCircleOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  ReadOutlined,
  TableOutlined,
  UserOutlined,
  BankOutlined,
  NotificationOutlined,
  InboxOutlined,
  UsergroupAddOutlined,
  RollbackOutlined,
  UnorderedListOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  AppstoreOutlined,
  ShopOutlined,
  CreditCardOutlined,
  WalletOutlined,
  HomeOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const adminNavTree = [
  {
    scope: ["admin", "staff"],
    key: "admin",
    featured: true,
    path: `${APP_PREFIX_PATH}/admin`,
    title: "sidenav.admin",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        scope: ["admin", "staff"],
        key: "admin-dashboard",
        path: `${APP_PREFIX_PATH}/admin/dashboard`,
        title: "sidenav.admin-dashboard",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-financial-management",
        path: `${APP_PREFIX_PATH}/admin/financial-management`,
        title: "sidenav.admin-financial_management",
        icon: BankOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "admin-financial-management-dashboard",
            path: `${APP_PREFIX_PATH}/admin/financial-management/dashboard`,
            title: "sidenav.admin-financial_management_dashboard",
            icon: DashboardOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-reconciliation",
            path: `${APP_PREFIX_PATH}/admin/financial-management/reconciliation`,
            title: "sidenav.admin-financial_management_reconciliation",
            icon: ReadOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: "admin-financial-management-transactions",
            path: `${APP_PREFIX_PATH}/admin/financial-management/transactions`,
            title: "sidenav.admin-financial_management_transactions",
            icon: FileDoneOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-movements",
            path: `${APP_PREFIX_PATH}/admin/financial-management/movements`,
            title: "sidenav.admin-financial_management_movements",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-categories",
            path: `${APP_PREFIX_PATH}/admin/financial-management/categories`,
            title: "sidenav.admin-financial_management_categories",
            icon: AppstoreAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-sub-categories",
            path: `${APP_PREFIX_PATH}/admin/financial-management/sub-categories`,
            title: "sidenav.admin-financial_management_sub_categories",
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-companies",
            path: `${APP_PREFIX_PATH}/admin/financial-management/companies`,
            title: "sidenav.admin-financial_management_companies",
            icon: HomeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-banks",
            path: `${APP_PREFIX_PATH}/admin/financial-management/banks`,
            title: "sidenav.admin-financial_management_banks",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-accounts",
            path: `${APP_PREFIX_PATH}/admin/financial-management/accounts`,
            title: "sidenav.admin-financial_management_accounts",
            icon: WalletOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-cards",
            path: `${APP_PREFIX_PATH}/admin/financial-management/cards`,
            title: "sidenav.admin-financial_management_cards",
            icon: CreditCardOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-entities",
            path: `${APP_PREFIX_PATH}/admin/financial-management/entities`,
            title: "sidenav.admin-financial_management_entities",
            icon: ShopOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-producers",
        path: `${APP_PREFIX_PATH}/admin/producers`,
        title: "sidenav.admin-producers",
        icon: UsergroupAddOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-products-approval",
        path: `${APP_PREFIX_PATH}/admin/products-approval`,
        title: "sidenav.admin-products-approval",
        icon: InboxOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-bank-accounts",
        path: `${APP_PREFIX_PATH}/admin/bank-accounts`,
        title: "sidenav.admin-bank-accounts",
        icon: BankOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-refunds",
        path: `${APP_PREFIX_PATH}/admin/refunds`,
        title: "sidenav.admin-refunds",
        icon: RollbackOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-payouts",
        path: `${APP_PREFIX_PATH}/admin/payouts`,
        title: "sidenav.admin-payouts",
        icon: EuroCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-users-students",
        path: `${APP_PREFIX_PATH}/admin/users-students`,
        title: "sidenav.admin-users-students",
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-buyers",
        path: `${APP_PREFIX_PATH}/admin/buyers`,
        title: "sidenav.admin-buyers",
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        scope: ["admin", "staff"],
        key: "admin-transactions",
        path: `${APP_PREFIX_PATH}/admin/transactions`,
        title: "sidenav.admin-transactions",
        icon: UnorderedListOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-invoices",
        path: `${APP_PREFIX_PATH}/admin/invoices`,
        title: "sidenav.admin-invoices",
        icon: FileDoneOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin"],
        key: "admin-notices",
        path: `${APP_PREFIX_PATH}/admin/notices`,
        title: "sidenav.admin-notices",
        icon: NotificationOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin"],
        key: "admin-dac7-dpi",
        path: `${APP_PREFIX_PATH}/admin/dac7-dpi`,
        title: "sidenav.admin-dac7-dpi",
        icon: FileProtectOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

let navigationConfig;

navigationConfig = [...adminNavTree];

export default navigationConfig;
