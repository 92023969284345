import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";
import { navigationConfig } from "configs/NavigationConfig";
import IntlMessage from "components/util-components/IntlMessage";

let breadcrumbData = {
  "": <IntlMessage id="home" />,
};

const createBreadcrumbData = (location) => {
  navigationConfig().forEach((elm, i) => {
    const assignBreadcrumb = (obj) => {
      // Check if paty is an array

      if (Array.isArray(obj.allowed_paths)) {
        for (var i = 0; i < obj.allowed_paths.length; i++) {
          // if (obj.allowed_paths[i].breadcrumb !== false) {
          breadcrumbData[obj.allowed_paths[i].path] = (
            <IntlMessage key={`Int-b${i}`} id={obj.allowed_paths[i].title} />
          );
          // }

          if (obj.allowed_paths[i].path.includes(".*")) {
            let reg = new RegExp(obj.allowed_paths[i].path);

            let res = location.match(reg);

            if (res) {
              if (res.length > 0)
                breadcrumbData[location] = <IntlMessage key={`Int-a${i}`} id={obj.allowed_paths[i].title} />;
            }
            //if (reg.test(location)) breadcrumbData[location] = <IntlMessage id={obj.allowed_paths[i].title} />;
          }
        }
      }

      return (breadcrumbData[obj.path] = <IntlMessage key={`Int-c${obj.title}`} id={obj.title} />);
    };

    assignBreadcrumb(elm);
    if (elm.submenu) {
      elm.submenu.forEach((elm) => {
        assignBreadcrumb(elm);
        if (elm.submenu) {
          elm.submenu.forEach((elm) => {
            assignBreadcrumb(elm);
          });
        }
      });
    }
  });
};

const createBreadcrumbItem = (url, index) => {
  let t = breadcrumbData[url];

  if (t === undefined) return;

  return (
    <Breadcrumb.Item key={`${url}-${index}`}>
      <Link to={url}>{breadcrumbData[url]}</Link>
    </Breadcrumb.Item>
  );
};

const BreadcrumbRoute = withRouter((props) => {
  const { location } = props;

  createBreadcrumbData(location.pathname);

  const pathSnippets = location.pathname.split("/").filter((i) => i);

  let buildBreadcrumb = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return createBreadcrumbItem(url, index);
  });

  // Filter all undefined Breadcrumbs
  buildBreadcrumb = buildBreadcrumb.filter((el) => el !== undefined);

  return (
    <React.Fragment>
      <div style={{ width: "auto" }}>
        <div style={{ float: "left", paddingTop: 3 }}>
          <Breadcrumb>{buildBreadcrumb}</Breadcrumb>
        </div>
      </div>
    </React.Fragment>
  );
});

const AppBreadcrumb = () => {
  return <BreadcrumbRoute />;
};

export default AppBreadcrumb;
