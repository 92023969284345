import { Result, Card } from "antd";

const AccountInactive = () => {
  return (
    <div className="my-5 container text-center">
      <img src="/img/logo.png" alt="SalesPark" />

      <Card>
        <Result
          status="403"
          title="A tua conta encontra-se inativa."
          extra={
            <div>
              <p>Para mais informações, contacta-nos através do email suporte@salespark.io</p>
            </div>
          }
        />
      </Card>
    </div>
  );
};

export default AccountInactive;
