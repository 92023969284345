import axios from "axios";

const api_request = (auth_data) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "development" ? 300000 : 15000,
    headers: {
      "Content-Type": "application/json",
      "x-email": auth_data.email,
      "x-guserid": auth_data.guserid,
      "x-token": auth_data.token,
    },
  });
};
const checkAuth = (resp) => {
  //TODO: #6:
  //Impement on components on catch: If status is 401 execute Google Signout
  try {
    if (resp.response.status === 401) window.location.reload();
  } catch (error) {}
};

export const comments_list = async (auth_data, limit, page, filter, select, orderBy, orderDirection) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/comments", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_vote_post = async (auth_data, course_id, chapter_id, lesson_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/comments/votes/${course_id}/${chapter_id}/${lesson_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_status_put = async (auth_data, course_id, chapter_id, lesson_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/comments/status/${course_id}/${chapter_id}/${lesson_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const completed_status_put = async (auth_data, course_id, chapter_id, lesson_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/comments/completed/${course_id}/${chapter_id}/${lesson_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_get = async (auth_data, comment_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/comments/${comment_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_reply_post = async (auth_data, parent_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/comments/reply/${parent_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_incompleted_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/comments/incompleted")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_delete = async (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/comments/${_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
