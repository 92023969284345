import axios from "axios";

const api_request = new axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "development" ? 300000 : 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const server_auth_user = async (payload) => {
  return new Promise((resolve, reject) => {
    try {
      api_request
        .post("/auth", {
          payload,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } catch (err) {
      reject({ ...err });
    }
  });
};

export const auth_reset_password = async (payload) => {
  return new Promise((resolve, reject) => {
    try {
      api_request
        .post("/auth/reset-password", {
          payload,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } catch (err) {
      reject({ ...err });
    }
  });
};
