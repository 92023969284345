import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Tag, Badge } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
// import { PopupButton } from "@typeform/embed-react";
import { navigationConfig } from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import { PushpinOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) => (isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString());

const setDefaultOpen = (key, mobile) => {
  // if (mobile) return [];
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }

  return keyList;
};
const scopedRoute = (scope, menu) => {
  if (scope === undefined) return false;
  return scope.some((r) => menu.scope.includes(r));
};
const SideNavContent = (props) => {
  const { user, sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle, scope } = props;

  // console.log("SideNavContent ", navigationConfig);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const _openedKeys = setDefaultOpen(routeInfo?.key, isMobile);

  return (
    <>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={_openedKeys}
        className={hideGroupTitle ? "hide-group-title" : ""}
      >
        {navigationConfig()?.map((menu, index) =>
          menu.submenu.length > 0 ? (
            <React.Fragment key={menu.key}>
              {scopedRoute(scope, menu) === true && (
                <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
                  {menu.submenu.map((subMenuFirst, index) =>
                    subMenuFirst.submenu.length > 0 ? (
                      <React.Fragment key={subMenuFirst.key}>
                        {scopedRoute(scope, subMenuFirst) === true && (
                          <SubMenu
                            icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                            key={subMenuFirst.key}
                            title={setLocale(localization, subMenuFirst.title)}
                          >
                            {/*  Popup Menu */}
                            {subMenuFirst.submenu.map((subMenuSecond, index) => (
                              <Menu.Item key={`${subMenuSecond.key}`}>
                                {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                                <span>
                                  {setLocale(localization, subMenuSecond.title)}
                                  {subMenuSecond?.showNumIncompletedComments === true &&
                                    props.incompletedCommentsNumber > 0 && (
                                      <Badge
                                        style={{ marginLeft: 4 }}
                                        overflowCount={10}
                                        count={props.incompletedCommentsNumber}
                                      />
                                    )}
                                </span>
                                <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                              </Menu.Item>
                            ))}
                          </SubMenu>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {scopedRoute(scope, subMenuFirst) === true && (
                          <Menu.Item key={subMenuFirst.key}>
                            {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                            <span>
                              {setLocale(localization, subMenuFirst.title)}{" "}
                              {subMenuFirst?.featured === true && (
                                <Tag className="smallTag" color="#87d068">
                                  <PushpinOutlined /> NOVO
                                </Tag>
                              )}
                            </span>
                            {subMenuFirst.new_tab === true ? (
                              <Link to={{ pathname: subMenuFirst.path }} target="_blank" />
                            ) : (
                              <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                            )}
                          </Menu.Item>
                        )}
                      </React.Fragment>
                    )
                  )}
                </Menu.ItemGroup>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {scopedRoute(scope, menu) === true && (
                <Menu.Item key={menu.key}>
                  {menu.icon ? <Icon type={menu?.icon} /> : null}
                  <span>{setLocale(localization, menu.title)}</span>
                  {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
                </Menu.Item>
              )}
            </React.Fragment>
          )
        )}

        {/* <div style={{ padding: 10, marginTop: 15 }}>
          <PopupButton
            id="nCFUXdEo"
            style={{ all: "unset", width: "100%" }}
            hidden={{
              name: user?.name,
              email: user?.email,
            }}
          >
            <Button type="primary" className="sp-button pulsingButtonSalesParkIgnite">
              <ArrowRightOutlined /> SalesPark Ignite Live
            </Button>
            <div style={{ lineHeight: "15px", textAlign: "center", marginTop: 5 }}>
              <small className="text-muted">Clica para mais informação!</small>
            </div>
          </PopupButton>
        </div> */}
      </Menu>
    </>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization, scope } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig().map((menu, i) =>
        menu.submenu.length > 0 ? (
          <React.Fragment key={i}>
            {scopedRoute(scope, menu) === true && (
              <SubMenu
                key={menu.key}
                popupClassName="top-nav-menu"
                title={
                  <span>
                    {menu.icon ? <Icon type={menu?.icon} /> : null}
                    <span>{setLocale(localization, menu.title)}</span>
                  </span>
                }
              >
                {menu.submenu.map((subMenuFirst, i) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <React.Fragment key={i}>
                      {scopedRoute(scope, menu) === true && (
                        <SubMenu
                          key={subMenuFirst.key}
                          popupClassName="top-nav-menu"
                          icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                          title={setLocale(localization, subMenuFirst.title)}
                        >
                          {subMenuFirst.submenu.map((subMenuSecond) => (
                            <Menu.Item key={subMenuSecond.key}>
                              <span>{setLocale(localization, subMenuSecond.title)}</span>
                              <Link to={subMenuSecond.path} />
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      )}
                    </React.Fragment>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                      <span>{setLocale(localization, subMenuFirst.title)}</span>
                      <Link to={subMenuFirst.path} />
                    </Menu.Item>
                  )
                )}
              </SubMenu>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {scopedRoute(scope, menu) === true && (
              <Menu.Item key={menu.key}>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu?.title)}</span>
                {menu.path ? <Link to={menu.path} /> : null}
              </Menu.Item>
            )}
          </React.Fragment>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />;
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
