import React from "react";
import { Tooltip } from "antd";
import { SafetyCertificateTwoTone } from "@ant-design/icons";

const VerifiedAccount = ({ verified, spaceAfter = true, spaceBefore = false }) => {
  if (!verified) return <></>;
  return (
    <span style={{ marginLeft: spaceBefore ? 5 : 0, marginRight: spaceAfter ? 5 : 0 }}>
      <Tooltip title="Conta Verificada">
        <SafetyCertificateTwoTone />
      </Tooltip>
    </span>
  );
};
export default VerifiedAccount;
