import React, { useState } from "react";
import { Tooltip, Button, Modal } from "antd";
import { WarningOutlined, LinkOutlined } from "@ant-design/icons";

const getTitle = (status) => {
  switch (status) {
    case "unsubscribed":
      return "Subscrição cancelada";
    case "bounced":
      return "E-mail não entregue (caixa de entrada cheia ou endereço de e-mail inválido)";
    case "junk":
      return "E-mails marcados como spam";
    default:
      return "E-mail não entregue";
  }
};

const MoreInfo = () => {
  return (
    <>
      <br />
      <strong>
        É importante corrigir esta situação para garantir entrega de e-mails com informação importante (inclusive
        legal ou fiscal).
      </strong>
      <br />
      <br />
      <Button type="primary">
        <LinkOutlined /> Clica aqui para saber mais.
      </Button>
    </>
  );
};
const Content = ({ data, isWarning }) => {
  if (isWarning) {
    return (
      <>
        <h4>Baixa taxa de abertura de e-mails.</h4>
        <p>
          A taxa de abertura dos nossos e-mails foi de <strong>{(data?.openedRate * 100).toFixed(0)}%</strong> nos
          últimos <strong>{data?.sent}</strong> emails.
          <br /> Certifica-te que estás a receber e-mails de conteúdo importante (inclusive legal ou fiscal).
        </p>
      </>
    );
  }

  if (data.status === "unsubscribed") {
    return (
      <>
        <h4>{getTitle(data.status)}</h4>
        <p>
          Foi solicitado a remoção do teu endereço de e-mail da nossa lista de envio.
          <MoreInfo />
        </p>
      </>
    );
  }
  if (data.status === "bounced") {
    return (
      <>
        <h4>{getTitle(data.status)}</h4>
        <p>
          O teu endereço de e-mail não está a receber os nossos e-mails.
          <MoreInfo />
        </p>
      </>
    );
  }

  if (data.status === "junk") {
    return (
      <>
        <h4>{getTitle(data.status)}</h4>
        <p>
          Os nosso foram marcados como spam.
          <MoreInfo />
        </p>
      </>
    );
  }
  return (
    <>
      <h4>{getTitle(data.status)}</h4>
      <p>
        O teu endereço de e-mail não está a receber os nossos e-mails.
        <MoreInfo />
      </p>
    </>
  );
};

const MailerLiteStatus = ({ data }) => {
  const [detailsModal, setDetailsModal] = useState({ visible: false, data: null });

  //active, unsubscribed, unconfirmed, bounced or junk.
  if (!data) return <></>;

  if (!data.status) return <></>;

  let title = "Existem problemas na entrega dos e-mails";
  let isJustWarning = false;

  // is active and sent > 5 and openedRate <= 0.5, warning needed
  if (data.sent > 5 && data.status === "active" && data.openedRate <= 0.5) {
    isJustWarning = true;
    title = "Baixa taxa de abertura de e-mails";
  }

  // is active and openedRate > 0.5, no warning needed
  if (data.status === "active" && isJustWarning === false) {
    return <></>;
  }

  return (
    <>
      <div id="email-status" style={{ marginRight: 40 }}>
        <Tooltip mouseEnterDelay={1} title={title}>
          <Button
            type={isJustWarning ? "default" : "primary"}
            size="small"
            danger={isJustWarning ? false : true}
            onClick={() => setDetailsModal({ visible: true, data: data })}
          >
            <WarningOutlined />{" "}
            {isJustWarning ? "Baixa taxa de abertura de e-mails" : "Problemas na entrega de e-mails"}
          </Button>
        </Tooltip>
      </div>
      <Modal
        title={title}
        visible={detailsModal.visible}
        onCancel={() => setDetailsModal({ visible: false, data: null })}
        footer={[
          <Button key="close" onClick={() => setDetailsModal({ visible: false, data: null })}>
            Fechar
          </Button>,
        ]}
      >
        <Content data={detailsModal.data} isWarning={isJustWarning} />
      </Modal>
    </>
  );
};

export default React.memo(MailerLiteStatus);
