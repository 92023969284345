import dicebarString from "utils/dicebear";
export const getProfileImage = (data) => {
  if (data !== undefined) {
    let image = !!data.picture ? data.picture : data.user.picture;
    if (image === "" || image === null || image === undefined) {
      // let name = data.name.replace(/ /g, "+").replace(/\s\s+/g, "+").replace(/#/g, "");
      // return `https://api.dicebear.com/7.x/initials/svg?seed=${name}`;
      dicebarString(data.name);
    }
    return image;
  }
};
