export const iam_user = [
  "Não consigo realizar a compra",
  "Informações sobre pagamento",
  "Reembolso ou cancelamento",
  "Alteração de dados de registo",
  "Documento de compra",
  "Outro assunto",
];

export const iam_producer = [
  "Dificuldade em aceder à minha conta",
  "Alteração do e-mail da conta",
  "Revisão de produtos",
  "Revisão de contas bancárias",
  "Comissões e retirar valores",
  "Outro assunto",
];

export const iam_affiliated = ["Outro assunto"];
export const iam_partner = ["Outro assunto"];
