import React from "react";
import { Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Flex from "components/shared-components/Flex";
import { useSelector } from "react-redux";

const NotFound = () => {
  const theme = useSelector((state) => state.theme.currentTheme);
  return (
    <div className="auth-container">
      <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
        <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
          <div>
            <img className="img-fluid" src={`/img/${theme === "light" ? "logo.png" : "logo-white.png"}`} alt="" />
          </div>
          <div className="container">
            <Row align="middle">
              <Col xs={24} sm={24} md={8}>
                <h1 className="font-weight-bold mb-4 display-4">Oops! Página não encontrada</h1>
                <p className="font-size-md mb-4">
                  Parece que te perdeste, mas não te preocupes que vamos te ajudar a encontrar o caminho certo.
                </p>
                <Link to="/dashboard">
                  <Button type="primary" icon={<ArrowLeftOutlined />}>
                    Voltar à página Inicial
                  </Button>
                </Link>
              </Col>
              <Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
                <img className="img-fluid mt-md-0 mt-4" src="/img/others/img-20.png" alt="" />
              </Col>
            </Row>
          </div>
          <Flex mobileFlex={false} justifyContent="between">
            <span>
              Copyright &copy; {`${new Date().getFullYear()}`}{" "}
              <span className="font-weight-semibold">SalesPark.io</span>
            </span>
            <div>
              <a
                className="text-gray"
                href="https://salespark.io/redirect/?u=general-terms-of-use"
                onClick={(e) => e?.preventDefault()}
              >
                Termos & Políticas
              </a>
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
