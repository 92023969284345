import { Result, Card } from "antd";
import SupportLocked from "./components/util-components/SupportRequestForm";
const Locked = () => {
  return (
    <div className="my-5 container ">
      <img src="/img/logo.png" alt="SalesPark" />

      <Card>
        <Result status="warning" title="Existe um problema com a tua conta." extra={<SupportLocked />} />
      </Card>
    </div>
  );
};

export default Locked;
