import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import AppViews from "views/app-views";
import { Layout, Grid, Alert, Button, Modal, Spin, notification } from "antd";
import { useAuth } from "context/Auth";
import { PlayCircleTwoTone } from "@ant-design/icons";
import getVideo from "configs/NavigationVideos";
import HelpVideo from "./HelpVideo";
import { navigationConfig } from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { app_active_notice_get } from "api/admin";
import { producer_reactivate_post } from "api/producers";
import { useAuth0 } from "@auth0/auth0-react";
import { comment_incompleted_get } from "../../api/comments";
// import AccountStatus from "../../components/layout-components/AccountStatus";
const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const { auth } = useAuth();
  const { logout } = useAuth0();
  const [appNotice, setAppNotice] = useState([]);
  const [inactiveUser, setInactiveUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [memoizedPageVideo, setMemoizedPageVideo] = useState(undefined);
  const [incompletedCommentsNumber, setIncompletedCommentsNumber] = useState(0);

  const windowLocation = window.location.pathname;

  useEffect(() => {
    const _videoData = getVideo(windowLocation);
    setMemoizedPageVideo(_videoData);

    loadAppNotices(auth);
    if (auth?.data?.inactive_user === true) setInactiveUser(true);
    comment_incompleted_get(auth.data).then((res) => {
      setIncompletedCommentsNumber(res.data?.count || 0);
    });
    return () => {};
  }, []);

  useEffect(() => {
    const _videoData = getVideo(windowLocation);
    setMemoizedPageVideo(_videoData);
  }, [windowLocation]);

  const reactivateAccount = async () => {
    setIsLoading(true);
    const res = await dbReactiveAccount();
    if (!res.status) {
      notification.error({
        message: "Erro",
        description: "Ocorreu um erro ao reativar a conta",
      });
      setIsLoading(false);
      return;
    }

    window.location.href = "/dashboard";
  };

  const dbReactiveAccount = () => {
    return new Promise((resolve) => {
      try {
        producer_reactivate_post(auth.data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const hasVideo = memoizedPageVideo !== undefined;

  const loadAppNotices = async () => {
    const res = await dbGetAppActuveNotices();
    if (!res.status) {
      return;
    }
    setAppNotice(res.data);
  };

  const dbGetAppActuveNotices = () => {
    return new Promise((resolve) => {
      try {
        app_active_notice_get(auth.data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const currentRouteInfo = utils.getRouteInfo(navigationConfig(), location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());

  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  // SET Mobile width definition
  const { width } = getWindowDimensions();
  const isMobile = !screens.includes("lg") || width < 1280;

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };
  //FIXME: WORKAROUND
  const noHeader = ["/buyers/view-details/", "/students/view-details/"]; //"/products/edit/"

  const headerDisplay = (d) => {
    //if (location.pathname.includes(noHeader)) return false;

    if (noHeader.some((substring) => location.pathname.includes(substring))) return false;
    return d;
  };

  const getBreadcrumbStyle = () => {
    if (hasVideo && headerDisplay(currentRouteInfo?.breadcrumb) === true) {
      return {
        display: "flex",
        justifyContent: "space-between",
      };
    }

    return { position: "absolute", right: "15px" };
  };

  const isAdminPage = location.pathname.includes("/admin/");

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />

      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? (
          <SideNav incompletedCommentsNumber={incompletedCommentsNumber} routeInfo={currentRouteInfo} />
        ) : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div
            className={`app-content ${appNotice ? "app-content-notice" : ""}  ${isNavTop ? "layout-top-nav" : ""}`}
          >
            {/*
            TODO: No longer needed. 
             No information will be given to the producer in advance. 
             However, the company's details will be required before the funds can be raised.
             <AccountStatus authData={auth?.data} />
              */}
            {appNotice.length > 0 && (
              <div className="salespark-notice">
                {appNotice?.map((notice, index) => (
                  <Alert
                    key={index}
                    message={notice?.title || ""}
                    type={notice?.type || "info"}
                    banner
                    action={
                      notice?.showMore && (
                        <Link to={{ pathname: notice.showMore }} target="_blank">
                          <Button size="small" type="link">
                            Saber mais...
                          </Button>
                        </Link>
                      )
                    }
                  />
                ))}
              </div>
            )}
            <div style={getBreadcrumbStyle()}>
              <PageHeader display={headerDisplay(currentRouteInfo?.breadcrumb)} title={currentRouteInfo?.title} />

              {hasVideo && !isAdminPage && <HelpVideo pageVideo={memoizedPageVideo} />}
            </div>
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer apiVersion={auth?.data?.apiVersion} />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
      <Modal
        destroyOnClose={true}
        visible={inactiveUser}
        title="Conta Inativa"
        closable={false}
        footer={[
          <Button
            loading={isLoading}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            type="default"
            onClick={() => {
              logout();
            }}
          >
            Não
          </Button>,
          <Button
            loading={isLoading}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            type="primary"
            onClick={() => {
              reactivateAccount();
            }}
          >
            Sim, quero reativar a minha conta
          </Button>,
        ]}
      >
        <h3>Conta inativa por falta de atividade</h3>
        <p>
          A tua conta encontra-se inativa por falta de atividade nos últimos 90 dias.
          <br />
          Queres reativar a tua conta?
        </p>
      </Modal>
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;

  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
