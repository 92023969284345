import adminNavigationConfig from "./AdminNavigationConfig";
import defaultNavigationConfig from "./DefaultNavigationConfig";

//const navigationConfig = isAdmin ? adminNavigationConfig : defaultNavigationConfig;

export const navigationConfig = () => {
  const pathname = window?.location?.pathname;

  const isAdmin = pathname?.toLowerCase()?.startsWith("/admin");
  return isAdmin ? [...adminNavigationConfig] : [...defaultNavigationConfig];
};

//export default navigationConfig;
