import React, { useState } from "react";
import { Modal, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { server_auth_user } from "../../../api/auth";
import { useSelector } from "react-redux";
import { useAuth } from "../../../context/Auth";
import Package from "../../../../package.json";
import moment from "moment-timezone";

import {
  toggleCollapsedNav,
  onNavTypeChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onSwitchTheme,
  onLocaleChange,
  onDirectionChange,
} from "redux/actions/Theme";
import store from "redux/store";

// Generate random number between 11 e 30
const randomXmasImage = Math.floor(Math.random() * (30 - 11 + 1)) + 11;
const env = process.env.REACT_APP_ENVIRONMENT.toLowerCase() || "development";

const isXMAS = () => {
  let today = moment();
  let xmasStart = moment().month(11).date(1);
  //let xmasStart = moment().month(11).date(1);
  let xmasEnd = moment().month(11).date(30);
  return today.isBetween(xmasStart, xmasEnd);
};

const normalStyle = {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  paddingTop: "15vh",
};

const xmasStyle = {
  backgroundImage: `url("/img/xmas/xmas${randomXmasImage.toString()}.jpg")`,
};

const Authentication = ({ children }) => {
  const { logout, error, isLoading, user, isAuthenticated, loginWithRedirect } = useAuth0();
  const { setAuth } = useAuth();
  const [loginTries, setLoginTries] = useState(1);
  const [tryMsg, setTryMsg] = useState("A iniciar sessão");

  const [loginStatus, setLoginStatus] = useState({
    running: false,
    completed: false,
    success: false,
  });

  const theme = useSelector((state) => state.theme.currentTheme);
  //const isXMAS = false;

  const areEqual = (prevProps, nextProps) => true;

  const Loader = ({ tryMsg, version }) => {
    return (
      <>
        <div className="loader-msg">{tryMsg}</div>
        <div className="loader-version">{version}</div>
      </>
    );
  };

  const MemoizedLoader = React.memo(Loader);

  /**
   * Start Authentication
   */
  const serverAuth = async () => {
    if (loginStatus.completed) return;

    setLoginStatus({
      running: true,
      completed: false,
      success: false,
    });
    try {
      let lt = loginTries;
      setLoginTries(lt + 1);

      const ret = await server_auth_user({
        guserid: user.sub,
        name: user.name,
        picture: user.picture,
        email: user.email,
      });

      if (ret.status === 200) {
        try {
          let c = ret.data;
          c.user = user;

          const adminToken = localStorage.getItem(`${env}-salespark-admin-token`);

          //TODO: Workaround to change Auth0 pic to Custom
          if (!!ret.data.picture) c.user.picture = ret.data.picture;
          if (!!ret.data.name) c.user.name = ret.data.name;
          c.user.onboarding_completed = ret.data.onboarding_completed;
          c.user.terms_accepted = ret.data.terms_accepted;
          c.user.settings = ret.data.settings;
          c.user.id = ret.data.id;
          c.registerDate = ret.data.registerDate;
          c.verifiedAccount = ret.data.verifiedAccount || false;
          c.company_legal_type = ret.data.company_legal_type;

          if (adminToken) {
            c.admin_token = adminToken;
          }

          if (c.user.settings) {
            store.dispatch(toggleCollapsedNav(c.user.settings.navCollapsed));
            store.dispatch(onNavTypeChange(c.user.settings.navType));
            store.dispatch(onTopNavColorChange(c.user.settings.topNavColor));
            store.dispatch(onHeaderNavColorChange(c.user.settings.headerNavColor));
            store.dispatch(onSwitchTheme(c.user.settings.currentTheme));
            store.dispatch(onDirectionChange(c.user.settings.direction));
            store.dispatch(onLocaleChange(c.user.settings.locale));
          }
          setAuth({
            isSignedIn: true,
            data: c,
          });

          setTimeout(
            () => {
              setLoginStatus({
                running: false,
                completed: true,
                success: true,
              });
            },
            isXMAS() ? 2000 : 0
          );
          localStorage.setItem(
            "SalesPark-AuthBasic",
            JSON.stringify({ id: c.id, name: c.name, email: c.email, picture: c.picture })
          );
        } catch (error) {}
      } else {
        setLoginStatus({
          running: false,
          completed: true,
          success: false,
        });
      }
    } catch (error) {
      let lt = loginTries;
      if (lt <= 5) {
        setTimeout(() => {
          setTryMsg(`A tentar autenticar. (tentativa ${lt}/5)`);
          setLoginStatus({
            running: false,
            completed: false,
            success: false,
          });
        }, 2500);
      } else {
        setLoginStatus({
          running: false,
          completed: true,
          success: false,
        });
      }
    }
  };

  /**
   * Logout user
   */
  const UserLogout = () => {
    logout();
  };

  const Content = () => {
    if (error) {
      Modal.error({
        title: "Oops!",
        content:
          'Não foi possível iniciar a sessão.<br/>Isto pode acontecer porque não foi confirmado/permitido acesso durante o processo de autenticação.<br/><br/><a href="https://salespark.io/redirect/?u=nao-foi-possivel-iniciar-a-sessao" target="_blank" rel="noreferrer">Saber mais acerca deste erro.</a>',
        onOk: () => {
          logout();
        },
      });
    }

    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect();
    }

    if (!loginStatus.running && loginStatus.completed && !loginStatus.success) {
      return (
        <div className={`${theme === "light" ? "bg-white" : ""}`} style={{ height: "100vh" }}>
          <div className="container-fluid d-flex flex-column justify-content-between  px-md-4 pb-md-4 pt-md-1">
            <div>
              <img
                className="img-fluid"
                src={`/img/${theme === "light" ? "logo_grey.svg" : "logo_white.svg"}`}
                alt=""
              />
            </div>
            <div className="container">
              <div className="text-center mb-5">
                <img
                  style={{ width: "100%", maxWidth: 500, padding: 50 }}
                  className="img-fluid"
                  src="/img/others/img-22.png"
                  alt=""
                />
                <h1 className="font-weight-bold mb-4">Oops! Não foi possível efetuar o login!</h1>
                <h5>Pode ser um erro temporário. Tenta novamente dentro de alguns minutos.</h5>
                <h5>
                  Caso o erro persista, entra em&nbsp;
                  <a target="_blank" rel="noreferrer" href="https://salespark.io/redirect/?u=central-ajuda">
                    contacto com o suporte
                  </a>
                  .
                </h5>
                <Button onClick={UserLogout} type="dashed" danger style={{ marginBottom: 15 }}>
                  Limpar sessões / Logout
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button onClick={loginWithRedirect} type="primary" style={{ marginBottom: 15 }}>
                  Tentar novamente
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (
      isAuthenticated &&
      !loginStatus.running &&
      loginStatus.completed &&
      loginStatus.success &&
      !user?.email_verified
    ) {
      return (
        <div className=" mt-50">
          <div className="ui middle aligned center aligned grid">
            <div class="ui center aligned yellow very padded segment">
              <div className="column">
                <div className="content">
                  <div className="ui">
                    <img
                      alt=""
                      className="ui centered medium rounded bordered image"
                      style={{ maxWidth: "120px" }}
                      src="../images/logo_250.png"
                    />
                  </div>

                  <h1>Só falta mais um passo!</h1>
                  <h3>Agora é necessário confirmares o e-mail que te acabamos de enviar.</h3>
                  <p>
                    Necessitas de ajuda?{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://salespark.io/redirect/?u=confirmacao-de-endereco-de-e-mail"
                    >
                      Clica aqui
                    </a>
                  </p>
                  <br />
                  <button onClick={logout} className="ui button mini basic blue">
                    Inciar sessão com outros dados.
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (isAuthenticated && !loginStatus.running && loginStatus.completed && loginStatus.success) {
      // INFO: Comment to edit loading page
      return children;
    }

    // LOADING **********************************************************************
    return (
      <div className={isXMAS() ? "auth-page-xmas" : "auth-page"} style={isXMAS() ? xmasStyle : {}}>
        <div style={normalStyle}>
          <div>
            <div className="loader-logo">
              <img
                width={150}
                className="img-fluid"
                src={`/img/${theme === "light" ? "logo_grey.svg" : "logo_white.svg"}`}
                alt=""
              />
            </div>
            <div className="loader"></div>
            <MemoizedLoader tryMsg={tryMsg} version={Package?.version} />
            {isXMAS() && <div className="loader-msg-xmas">🎄 Boas Festas 🎄</div>}
          </div>
        </div>
      </div>
    );
  };

  if (isAuthenticated && !loginStatus.running && !loginStatus.completed) {
    serverAuth();
    // return loader();
  }

  return <Content />;
};

export default Authentication;
